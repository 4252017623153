<template>
  <div>
     <b-modal @hidden="resetModal" ref="modal-create" size="lg" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
          <div class="d-flex align-items-center">
            <i class="bi bi-person-workspace me-2 text-primary"></i>
            <div>
              <h5 class="text-primary mb-0 text-dark">
                Create a Group
              </h5>
              <p class="font-size-12 mb-0 text-muted fw-normal">Configure the pricing for each group individually</p>
            </div>
          </div>
        </template>
        <div class="row align-items-top">
          <div class="mb-3 col-12">
              <label>Group Name</label>
              <div class="input-group">
                <input
                  v-model.trim="formData.name"
                  type="text"
                  class="form-control"
                  placeholder="Group Name"
                  name="name"
                  :class="{
                  'is-invalid':
                  modalSubmit && $v.formData.name.$error,
                  }"
                  />
              </div>
              <div
                v-if="modalSubmit && $v.formData.name.$error"
                class="invalid-feedback d-block"
                >
                <span v-if="!$v.formData.name.required"
                    >Group name is required.</span
                    >
              </div>
          </div>
          <div class="mb-3 col-12">
              <label>Group Remark</label>
              <div class="input-group">
                <input
                  v-model.trim="formData.remark"
                  type="text"
                  class="form-control"
                  placeholder="Group Remark"
                  name="remark"
                  :class="{
                  'is-invalid':
                  modalSubmit && $v.formData.remark.$error,
                  }"
                  />
              </div>
              <div
                v-if="modalSubmit && $v.formData.remark.$error"
                class="invalid-feedback d-block"
                >
                <span v-if="!$v.formData.name.required"
                    >Group remark is required.</span
                    >
              </div>
          </div>
          <div class="col-12 mb-3">
            <div class="d-flex align-items-center mb-2">
              <div>
                <label class="mb-0">Category Pricing</label>
                <small class="d-block text-muted">Configure category pricing individually</small>
              </div>
              <div class="ms-auto">
                <b-dropdown id="dropdown-grouped" right class="mb-0" variant="info" ref="dropdown" v-if="formData.categories.length && !loading">
                  <template #button-content>
                    <i class="bi bi-gear"></i> <span class="d-none d-lg-inline-block">Quick Update</span> 
                  </template>
                  <b-dropdown-header>
                    Quickly update the value or change the type based on <br>the selected and input values below
                  </b-dropdown-header>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-group id="dropdown-group-1" header="">
                    <b-dropdown-form class="p-3 pt-2">
                      <div class="row">
                        <div class="col-12">
                          <div class="d-flex align-items-center mb-2">
                            <div class="w-100  me-1">Discount Type</div>
                             <select class="form-select form-select-custom me-1 flex-fill" aria-label="" v-model="quickUpdateByCategory.discount_type">
                              <option value="fixed_amount">Fixed Amount</option>
                              <option value="percentage">Percentage (%)</option>
                            </select>
                          </div>
                          <div class="d-flex align-items-center mb-2">
                            <div class="w-100 me-1">Value (-)</div>
                            <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdateByCategory.merchant_price" placeholder="Value (-)" maxlength="5" @input="inputNumberOnly($event, 'quickUpdateByCategory','merchant_price')" >  
                          </div>
                        </div>
                      </div>
                      </b-dropdown-form> 
                  </b-dropdown-group>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-form class="p-3 py-1">
                   <div class="d-flex gap-2">
                   <button type="button" class="btn btn-secondary w-100" @click="resetQuickUpdate()">Reset All</button>
                   <button type="button" class="btn btn-info  w-100" @click="quickUpdateAll('categories')">Update All</button>
                  </div>
                  </b-dropdown-form>
                </b-dropdown>
              </div>
            </div>
            
            <div class="bg-dark px-2 pt-2 rounded-3">
              <div class="d-lg-flex align-items-center mb-2 pb-2 justify-content-between" :class="{'border-bottom border-secondary': (formData.categories.length-1) !==index}" 
              v-for="(value,index) in formData.categories" :key="index">
              
                <div class="fw-medium font-size-14 mb-2 mb-lg-0 text-white text-lg-center">
                  <img :src="value.category_image" class="rounded me-2 bg-white" alt="..." style="height: 50px;">
                  {{ value.category_name }}
                </div>
                <div class="d-flex align-items-center">
                    <select class="form-select form-select-custom me-1 flex-fill" aria-label="" v-model="value.discount_type">
                      <option value="fixed_amount">Fixed Amount</option>
                      <option value="percentage">Percentage (%)</option>
                    </select>
                    <div class="flex-fill">
                      <input type="text" class="form-control price-input" placeholder="Merchant Price" v-model="value.merchant_price"
                      @input="inputNumberOnlyV4($event,'formData','categories', 'merchant_price', index)">
                    </div>
                </div>
              </div>
            </div>
            
          </div>
          <div class="col-12 mb-3">
            <div class="d-lg-flex align-items-center mb-2">
              <div class="mb-0 mb-lg-0">
                <label class="mb-0">Poison Pricing</label>
                <small class="d-block text-muted">Configure all poison pricing settings</small>
              </div>
            </div>
            <div class="bg-dark px-2 pt-2 rounded-3">
              <div class="d-lg-flex align-items-center mb-2 pb-2 justify-content-between">
                <div class="fw-medium font-size-14 text-info mb-2 mb-lg-0 text-white">
                  <img src="@/assets/images/poison.png" class="rounded me-2" alt="..." style="height: 50px; width: 50px;">
                  Poison </div>
                <div class="d-flex align-items-center">
                    <select class="form-select form-select-custom me-1 flex-fill" aria-label="" v-model="formData.poison.discount_type" >
                      <option value="fixed_amount">Fixed Amount</option>
                      <option value="percentage">Percentage (%)</option>
                    </select>
                    <div class="flex-fill">
                      <input type="text" class="form-control price-input" placeholder="Merchant Price" v-model="formData.poison.merchant_price"
                      @input="inputNumberOnlyV5($event,'formData','poison', 'merchant_price')" >
                    </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 mb-0">
            <div class="d-flex align-items-center mb-2">
              <div class="mb-2 mb-lg-0 me-1">
                <label class="mb-0">Special Product Pricing</label>
                <small class="d-block text-muted">Configure product pricing individually</small>
              </div>
              <div class="ms-auto mb-lg-0 me-1" v-if="formData.products.length!==0">
                <b-dropdown id="dropdown-grouped" right class="mb-0" variant="info" ref="dropdown" v-if="formData.categories.length && !loading">
                  <template #button-content>
                    <i class="bi bi-gear"></i> <span class="d-none d-lg-inline-block">Quick Update</span> 
                  </template>
                  <b-dropdown-header>
                    Quickly update the value or change the type based on <br>the selected and input values below
                  </b-dropdown-header>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-group id="dropdown-group-1" header="">
                    <b-dropdown-form class="p-3 pt-2">
                      <div class="row">
                        <div class="col-12">
                          <div class="d-flex align-items-center mb-2">
                            <div class="w-100  me-1">Discount Type</div>
                             <select class="form-select form-select-custom me-1 flex-fill" aria-label="" v-model="quickUpdateByCategory.discount_type">
                              <option value="fixed_amount">Fixed Amount</option>
                              <option value="percentage">Percentage (%)</option>
                            </select>
                          </div>
                          <div class="d-flex align-items-center mb-2">
                            <div class="w-100 me-1">Value (-)</div>
                            <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdateByCategory.merchant_price" placeholder="Value (-)" maxlength="5" @input="inputNumberOnly($event, 'quickUpdateByCategory','merchant_price')" >  
                          </div>
                        </div>
                      </div>
                      </b-dropdown-form> 
                  </b-dropdown-group>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-form class="p-3 py-1">
                   <div class="d-flex gap-2">
                   <button type="button" class="btn btn-secondary w-100" @click="resetQuickUpdate()">Reset All</button>
                   <button type="button" class="btn btn-info  w-100" @click="quickUpdateAll('products')">Update All</button>
                  </div>
                  </b-dropdown-form>
                </b-dropdown>
              </div>
              <div v-if="formData.products.length!==0">
                <button class="btn btn-info" @click="addItems()"><i class="bi bi-plus-circle me-lg-1"></i> 
                  <span class="d-none d-lg-inline-block"> Add Products</span> 
                 </button>
              </div>
            </div>
            
            
            <div class="bg-dark px-2 pt-2 rounded-3" v-if="formData.products.length">
              <div class="d-lg-flex align-items-center mb-2 pb-2 justify-content-between" :class="{'border-bottom border-secondary': (formData.products.length-1) !==index}" 
              v-for="(value,index) in formData.products" :key="index">
              
                <div class="fw-medium font-size-14 text-info mb-2 mb-lg-0 text-white">
                  <div class="d-flex align-items-center">
                    <img :src="value.images[0]" class="rounded me-2 bg-white" alt="..." style="height: 50px;">
                    <span> {{ value.title }}</span>
                  </div>
                 </div>
                <div class="d-flex align-items-center">
                    <select class="form-select form-select-custom me-1 flex-fill" aria-label="" v-model="value.discount_type" >
                      <option value="fixed_amount">Fixed Amount</option>
                      <option value="percentage">Percentage (%)</option>
                    </select>
                    <div class="flex-fill">
                      <input type="text" class="form-control price-input" placeholder="Merchant Price" v-model="value.merchant_price"
                      @input="inputNumberOnlyV4($event,'formData','products', 'merchant_price', index)" >
                    </div>
                    <div class="flex-fill ms-1">
                     <button class="btn btn-light fw-medium text-danger" @click="formData.products.splice(index, 1);"><i class="bi bi-trash3-fill"></i></button>
                    </div>
                </div>
             
              
              </div>
            </div>
            <div class="bg-light text-center py-5 text-muted rounded-3" v-else>
              <div class="mb-2">Don't have any products yet? Do you want to set prices for individual products?</div>
              <button class="btn btn-info mb-0" @click="addItems()"><i class="bi bi-plus-circle me-1"></i> Add Products</button>
            </div>

          </div>
           <div class="col-12">
              <hr>
              <div class="d-grid gap-2 d-md-block text-end">
                 <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-create'].hide()">Cancel</button>
                 <button type="button" class="btn fw-medium btn-primary" @click="addRequest" :disabled="modalLoading || loading">
                 <span v-if="modalLoading"> Creating...</span>
                 <span v-else> Create </span>
                 <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                 </button>
              </div>
           </div>
        </div>
     </b-modal>
     <b-modal @hidden="resetModal" ref="modal-edit" size="lg" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
          <div class="d-flex align-items-center">
            <i class="bi bi-person-workspace me-2 text-primary"></i>
            <div>
              <h5 class="text-primary mb-0 text-dark">
                Edit {{updateData.name}} Group 
              </h5>
              <p class="font-size-12 mb-0 text-muted fw-normal">Configure the pricing for each group individually</p>
            </div>
          </div>
        </template>
        <div class="row align-items-top" v-if="loading">
          <div class="text-center py-5 text-center text-muted" slot="table-busy">
            <div
              class="spinner-border text-secondary my-2"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
            <br />
            <div>Loading...</div>
          </div>
        </div>
        <div class="row align-items-top" v-else>
          <div class="mb-3 col-12">
              <label>Group Name</label>
              <div class="input-group">
                <input
                  v-model.trim="formEditData.name"
                  type="text"
                  class="form-control"
                  placeholder="Group Name"
                  name="name"
                  :class="{
                  'is-invalid':
                  modalSubmit && $v.formEditData.name.$error,
                  }"
                  />
              </div>
              <div
                v-if="modalSubmit && $v.formEditData.name.$error"
                class="invalid-feedback d-block"
                >
                <span v-if="!$v.formEditData.name.required"
                    >Group name is required.</span
                    >
              </div>
          </div>
          <div class="mb-3 col-12">
              <label>Group Remark</label>
              <div class="input-group">
                <input
                  v-model.trim="formEditData.remark"
                  type="text"
                  class="form-control"
                  placeholder="Group Remark"
                  name="remark"
                  :class="{
                  'is-invalid':
                  modalSubmit && $v.formEditData.remark.$error,
                  }"
                  />
              </div>
              <div
                v-if="modalSubmit && $v.formEditData.remark.$error"
                class="invalid-feedback d-block"
                >
                <span v-if="!$v.formEditData.name.required"
                    >Group remark is required.</span
                    >
              </div>
          </div>
          <div class="col-12 mb-3">
            <div class="align-items-center">
              <label class="mb-1 me-2" for="status">Group Status</label>
              <div>
                <div class="form-check form-switch form-check-custom ms-1 mb-0 font-size-14">
                  <input class="form-check-input" type="checkbox" role="switch" id="statuss" v-model="formEditData.status" true-value="available" false-value="unavailable" :disabled="modalLoading">
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="d-flex align-items-center mb-2">
              <div>
                <label class="mb-0">Category Pricing</label>
                <small class="d-block text-muted">Configure category pricing individually</small>
              </div>
              <div class="ms-auto">
                <b-dropdown id="dropdown-grouped" right class="mb-0" variant="info" ref="dropdown" v-if="formEditData.categories.length">
                  <template #button-content>
                    <i class="bi bi-gear"></i> <span class="d-none d-lg-inline-block">Quick Update</span> 
                  </template>
                  <b-dropdown-header>
                    Quickly update the value or change the type based on <br>the selected and input values below
                  </b-dropdown-header>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-group id="dropdown-group-1" header="">
                    <b-dropdown-form class="p-3 pt-2">
                      <div class="row">
                        <div class="col-12">
                          <div class="d-flex align-items-center mb-2">
                            <div class="w-100  me-1">Discount Type</div>
                             <select class="form-select form-select-custom me-1 flex-fill" aria-label="" v-model="quickUpdateByCategory.discount_type">
                              <option value="fixed_amount">Fixed Amount</option>
                              <option value="percentage">Percentage (%)</option>
                            </select>
                          </div>
                          <div class="d-flex align-items-center mb-2">
                            <div class="w-100 me-1">Value (-)</div>
                            <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdateByCategory.merchant_price" placeholder="Value (-)" maxlength="5" @input="inputNumberOnly($event, 'quickUpdateByCategory','merchant_price')" >  
                          </div>
                        </div>
                      </div>
                      </b-dropdown-form> 
                  </b-dropdown-group>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-form class="p-3 py-1">
                   <div class="d-flex gap-2">
                   <button type="button" class="btn btn-secondary w-100" @click="resetQuickUpdate()">Reset All</button>
                   <button type="button" class="btn btn-info  w-100" @click="quickUpdateAll('categories')">Update All</button>
                  </div>
                  </b-dropdown-form>
                </b-dropdown>
              </div>
            </div>
            
            <div class="bg-dark px-2 pt-2 rounded-3">
              <div class="d-lg-flex align-items-center mb-2 pb-2 justify-content-between" :class="{'border-bottom border-secondary': (formEditData.categories.length-1) !==index}" 
              v-for="(value,index) in formEditData.categories" :key="index">
              
                <div class="fw-medium font-size-14 mb-2 mb-lg-0 text-white text-lg-center">
                  <img :src="value.category_image" class="rounded me-2 bg-white" alt="..." style="height: 50px;">
                  {{ value.category_name }}
                </div>
                <div class="d-flex align-items-center">
                    <select class="form-select form-select-custom me-1 flex-fill" aria-label="" v-model="value.discount_type">
                      <option value="fixed_amount">Fixed Amount</option>
                      <option value="percentage">Percentage (%)</option>
                    </select>
                    <div class="flex-fill">
                      <input type="text" class="form-control price-input" placeholder="Merchant Price" v-model="value.merchant_price"
                      @input="inputNumberOnlyV4($event,'formEditData','categories', 'merchant_price', index)">
                    </div>
                </div>
              </div>
            </div>
            
          </div>
          <div class="col-12 mb-3">
            <div class="d-lg-flex align-items-center mb-2">
              <div class="mb-0 mb-lg-0">
                <label class="mb-0">Poison Pricing</label>
                <small class="d-block text-muted">Configure all poison pricing settings</small>
              </div>
            </div>
            <div class="bg-dark px-2 pt-2 rounded-3">
              <div class="d-lg-flex align-items-center mb-2 pb-2 justify-content-between">
                <div class="fw-medium font-size-14 text-info mb-2 mb-lg-0 text-white">
                  <img src="@/assets/images/poison.png" class="rounded me-2" alt="..." style="height: 50px; width: 50px;">
                  Poison </div>
                <div class="d-flex align-items-center" v-if="formEditData.poison">
                    <select class="form-select form-select-custom me-1 flex-fill" aria-label="" v-model="formEditData.poison.discount_type" >
                      <option value="fixed_amount">Fixed Amount</option>
                      <option value="percentage">Percentage (%)</option>
                    </select>
                    <div class="flex-fill" v-if="formEditData.poison">
                      <input type="text" class="form-control price-input" placeholder="Merchant Price" v-model="formEditData.poison.merchant_price"
                      @input="inputNumberOnlyV5($event,'formEditData','poison', 'merchant_price')" >
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="d-flex align-items-center mb-2">
              <div class="mb-2 mb-lg-0 me-1">
                <label class="mb-0">Special Product Pricing</label>
                <small class="d-block text-muted">Configure product pricing individually</small>
              </div>
              <div class="ms-auto mb-lg-0 me-1" v-if="formEditData.products.length!==0">
                <b-dropdown id="dropdown-grouped" right class="mb-0" variant="info" ref="dropdown" v-if="formEditData.categories.length && !loading">
                  <template #button-content>
                    <i class="bi bi-gear"></i> <span class="d-none d-lg-inline-block">Quick Update</span> 
                  </template>
                  <b-dropdown-header>
                    Quickly update the value or change the type based on <br>the selected and input values below
                  </b-dropdown-header>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-group id="dropdown-group-1" header="">
                    <b-dropdown-form class="p-3 pt-2">
                      <div class="row">
                        <div class="col-12">
                          <div class="d-flex align-items-center mb-2">
                            <div class="w-100  me-1">Discount Type</div>
                             <select class="form-select form-select-custom me-1 flex-fill" aria-label="" v-model="quickUpdateByCategory.discount_type">
                              <option value="fixed_amount">Fixed Amount</option>
                              <option value="percentage">Percentage (%)</option>
                            </select>
                          </div>
                          <div class="d-flex align-items-center mb-2">
                            <div class="w-100 me-1">Value (-)</div>
                            <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdateByCategory.merchant_price" placeholder="Value (-)" maxlength="5" @input="inputNumberOnly($event, 'quickUpdateByCategory','merchant_price')" >  
                          </div>
                        </div>
                      </div>
                      </b-dropdown-form> 
                  </b-dropdown-group>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-form class="p-3 py-1">
                   <div class="d-flex gap-2">
                   <button type="button" class="btn btn-secondary w-100" @click="resetQuickUpdate()">Reset All</button>
                   <button type="button" class="btn btn-info  w-100" @click="quickUpdateAll('products')">Update All</button>
                  </div>
                  </b-dropdown-form>
                </b-dropdown>
              </div>
              <div v-if="formEditData.products.length!==0">
                <button class="btn btn-info" @click="addItems()"><i class="bi bi-plus-circle me-lg-1"></i> 
                  <span class="d-none d-lg-inline-block"> Add Products</span> 
                 </button>
              </div>
            </div>
            

            <div class="bg-dark px-2 pt-2 rounded-3" v-if="formEditData.products.length">
              <div class="d-lg-flex align-items-center mb-2 pb-2 justify-content-between" :class="{'border-bottom border-secondary': (formEditData.products.length-1) !==index}" 
              v-for="(value,index) in formEditData.products" :key="index">
              
                <div class="fw-medium font-size-14 text-info mb-2 mb-lg-0 text-white">
                  <div class="d-flex align-items-center">
                    <img :src="value.images[0]" class="rounded me-2 bg-white" alt="..." style="height: 50px;">
                    <span> {{ value.title }}</span>
                  </div>
                 </div>
                <div class="d-flex align-items-center">
                    <select class="form-select form-select-custom me-1 flex-fill" aria-label="" v-model="value.discount_type" >
                      <option value="fixed_amount">Fixed Amount</option>
                      <option value="percentage">Percentage (%)</option>
                    </select>
                    <div class="flex-fill">
                      <input type="text" class="form-control price-input" placeholder="Merchant Price" v-model="value.merchant_price"
                      @input="inputNumberOnlyV4($event,'formEditData','products', 'merchant_price', index)" >
                    </div>
                    <div class="flex-fill ms-1">
                     <button class="btn btn-light fw-medium text-danger" @click="formEditData.products.splice(index, 1);"><i class="bi bi-trash3-fill"></i></button>
                    </div>
                </div>
              </div>
            </div>
            <div class="bg-light text-center py-5 text-muted rounded-3" v-else>
              <div class="mb-2">Don't have any products yet? Do you want to set prices for individual products?</div>
              <button class="btn btn-info mb-0" @click="addItems()"><i class="bi bi-plus-circle me-1"></i> Add Products</button>
            </div>
          </div>
          <div class="col-12 mb-0">
            <p class="font-size-12 mb-0 text-muted fw-normal">
              Create by {{updateData.created_by}}  on {{formatDateTime(updateData.date_created)}}. <br> Last updated on  {{formatDateTime(updateData.date_updated)}} by {{ updateData.last_update_by }}.
            </p>
          </div>
        </div>
        <div class="row align-items-top">
          <div class="col-12">
              <hr>
              <div class="d-grid gap-2 d-md-block text-end">
                 <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-edit'].hide()">Cancel</button>
                 <button type="button" class="btn fw-medium btn-primary" @click="updateRequest" :disabled="modalLoading || loading">
                 <span v-if="modalLoading"> Updating...</span>
                 <span v-else> Update </span>
                 <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                 </button>
              </div>
           </div>
        </div>
     </b-modal>
     <b-modal @hidden="resetModal" ref="modal-view" size="md" centered hide-footer  content-class="overflow-hidden">
        <template #modal-title>
          <div class="d-flex align-items-center">
            <i class="bi bi-person-workspace me-2 text-primary"></i>
            <div>
              <h5 class="text-primary mb-0 text-dark">
                {{updateData.name}}'s Group 
              </h5>
              <p class="font-size-12 mb-0 text-muted fw-normal">Configure the pricing for each group individually</p>
            </div>
          </div>
        </template>
        <div class="row align-items-top" v-if="loading">
          <div class="text-center py-5 text-center text-muted" slot="table-busy">
            <div
              class="spinner-border text-secondary my-2"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
            <br />
            <div>Loading...</div>
          </div>
        </div>
        <div class="row align-items-top" v-else>
          <!-- <div class="mb-3 col-12">
              <label class="mb-1">Group Name</label>
              <div class="text-primary fw-medium">{{ updateData.name }}</div>
          </div>
          <div class="mb-3 col-12">
              <label class="mb-1">Group Remark</label>
              <div class="text-muted">{{ updateData.remark }}</div>
          </div>
          <div class="col-12 mb-3">
            <div class="align-items-center">
              <label class="mb-1" for="status">Group Status</label>
              <div>
                  <span v-if="updateData.status=='unavailable'" class="text-danger badge border border-danger fw-normal font-size-14 text-capitalize text-uppercase">{{updateData.status}}</span>
                  <span v-else-if="updateData.status=='available'" class="text-success badge border border-success fw-normal font-size-14 text-capitalize text-uppercase"> {{updateData.status}}</span>
                </div>
            </div>
          </div> -->
          <div class="col-12 mb-3">
            <div class="d-flex align-items-center mb-2">
              <div>
                <label class="mb-0">Category Pricing</label>
                <small class="d-block text-muted">All category pricing setting</small>
              </div>
            </div>
            
            <div class="bg-dark px-2 pt-2 rounded-3">
              <div class="d-lg-flex align-items-center mb-2 pb-2 justify-content-between" :class="{'border-bottom border-secondary': (updateData.categories.length-1) !==index}" 
              v-for="(value,index) in updateData.categories" :key="index">
              
                <div class="fw-medium font-size-14 mb-2 mb-lg-0 text-white text-lg-center">
                  <img :src="value.category_image" class="rounded me-2 bg-white" alt="..." style="height: 50px;">
                  {{ value.category_name }}
                </div>
                <div class="d-block text-primary-light text-end">
                    <div class="d-block font-size-12 text-gray"> All Items</div>
                    <div class="flex-fill" v-if="value.discount_type=='fixed_amount'">
                    -{{ convertCurrencyFormat(value.merchant_price,true)}}
                    </div>
                    <div v-else>
                     -{{value.merchant_price}}%
                    </div>
                </div>
              </div>
            </div>
            
          </div>
          <div class="col-12 mb-3">
            <div class="d-lg-flex align-items-center mb-2">
              <div class="mb-0 mb-lg-0">
                <label class="mb-0">Poison Pricing</label>
                <small class="d-block text-muted">All poison pricing settings setting</small>
              </div>
            </div>
            <div class="bg-dark px-2 pt-2 rounded-3">
              <div class="d-lg-flex align-items-center mb-2 pb-2 justify-content-between">
                <div class="fw-medium font-size-14 text-info mb-2 mb-lg-0 text-white">
                  <img src="@/assets/images/poison.png" class="rounded me-2" alt="..." style="height: 50px; width: 50px;">
                  Poison </div>
                  <div class="d-block text-primary-light text-end" v-if="updateData.poison">
                    <div class="d-block font-size-12 text-gray"> All Poison</div>
                    <div class="flex-fill" v-if="updateData.poison.discount_type=='fixed_amount'">
                    -{{ convertCurrencyFormat(updateData.poison.merchant_price,true)}}
                    </div>
                    <div v-else>
                     -{{updateData.poison.merchant_price}}%
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-0">
            <div class="d-flex align-items-center mb-2">
              <div class="mb-2 mb-lg-0 me-1">
                <label class="mb-0">Special Product Pricing</label>
                <small class="d-block text-muted">All special product pricing setting</small>
              </div>
            </div>
            

            <div class="bg-dark px-2 pt-2 rounded-3" v-if="updateData.products">
              <div class="d-lg-flex align-items-center mb-2 pb-2 justify-content-between" :class="{'border-bottom border-secondary': (updateData.products.length-1) !==index}" 
              v-for="(value,index) in updateData.products" :key="index">
              
                <div class="fw-medium font-size-14 text-info mb-2 mb-lg-0 text-white">
                  <div class="d-flex align-items-center">
                    <img :src="value.images[0]" class="rounded me-2 bg-white" alt="..." style="height: 50px;">
                    <span> {{ value.title }}</span>
                  </div>
                 </div>
                 <div class="d-block text-primary-light text-end">
                    <div class="d-block font-size-12 text-gray"> Item</div>
                    <div class="flex-fill" v-if="value.discount_type=='fixed_amount'">
                    -{{ convertCurrencyFormat(value.merchant_price,true)}}
                    </div>
                    <div v-else>
                     -{{value.merchant_price}}%
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     </b-modal>
     <b-modal @hidden="resetModal" ref="modal-merchants" size="lg" centered hide-footer content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="bi bi-people me-1 text-primary"></i> {{ updateData.name }}'s Merchants
           </h5>
        </template>
        <div class="row align-items-center">
          <div class="col-12">
            <div class="d-flex">
                <div class="app-search2 position-relative">
                  <input
                    type="search"
                    placeholder="Filter..."
                    class="form-control mb-3"
                    v-model.trim="searchKey"
                    autocomplete="no"
                    @keyup="filterByMerchant()"
                  />
                  <span class="uil-search text-info"></span>
                </div>
                <button
                  class="btn btn-light mb-3 ms-2 text-nowrap"
                  type="button"
                  @click="searchKey=''"
                >
                  <i class="uil-redo me-1"></i> Reset
              </button>
            </div>
          </div>
          <div class="col-12">
            <div
              class="table-responsive text-nowrap font-size-14 position-relative"
            >
            <table class="table align-middle mb-0" :class="{'table-hover':filterByMerchant().length>0 && !modalLoading}">
              <thead>
                <tr>
                  <th scope="col" class="fw-medium">Merchant</th>
                  <th scope="col" class="fw-medium">Status</th>
                </tr>
              </thead>
              <tbody class="table-group-divider">
                <tr v-for="(value, index) in filterByMerchant()" :key="index">
                  <td>{{ value.merchant_name }}</td>
                  <td>
                    <span v-if="value.status=='unavailable'" class="text-danger font-size-14 me-1 text-capitalize text-uppercase">&#9679; {{value.status}}</span>
                     <span v-else-if="value.status=='available'" class="text-success font-size-14 me-1 text-capitalize text-uppercase">&#9679; {{value.status}}</span>
                  </td>
                </tr>
                <tr v-if="!filterByMerchant().length && !loading && searchKey">
                  <td colspan="2" height="200" class="text-center text-muted">
                     Sorry, we couldn't find any results of "{{ searchKey }}".
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
         
          <div class="col-12">
              <hr>
              <div class="d-grid gap-2 d-md-block text-end">
                <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-merchants'].hide()">Cancel</button>
              </div>
          </div>
        </div>
     </b-modal>
     <Common ref="commonFunc" />
     <selectMultipleProduct
      ref="modalFunc1"
      :data="{
        accessUsername: accessUsername,
        accessToken: accessToken,
        updateData: updateData2,
      }"
      @updateItemsList="updateBankInfoFromChild"
    />
  </div>
</template>

<script>
  import appConfig from "@/app.config";
  import axios from "axios";
  import Swal from "sweetalert2";
  import Common from "@/components/common";
  import selectMultipleProduct from "@/views/pages/app/modal/selectMultipleProduct";
  import { 
    required
  } from "vuelidate/lib/validators";
  /**
   * Starter page
   */
  export default {
    components: {
      Common,
      selectMultipleProduct
    },
    props: {
      data: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        updateData: {
          preview_username:""
        },
        updateData2: {},
        accessToken: '',
        accessEmail: '',
        accessPhone: '',
        accessUsername: '',
        account_type: '',
        loading: false,
        modalSubmit: false,
        modalLoading: false,
        returnData: [],
        returnData2: [],
        searchKey:"",
        typeOfPrice:[
          "fixed_amount",
          "percentage",
        ],
        formData: {
          name:"",
          remark: "",
          categories: [],
          products: [],
          poison:{
            merchant_price: 0,
            discount_type:"percentage"
          }
        },
        formEditData: {
          id:"",
          name:"",
          remark: "",
          categories: [],
          products: [],
          poison:{
            merchant_price: 0,
            discount_type:"percentage",
          },
          created_date:"",
          updated_date:"",
          created_by: "",
          last_update_by: "",
          status:""
        },
        quickUpdateByCategory:{
          discount_type:"fixed_amount",
          merchant_price:0
        },
        quickUpdateByItems:{
          discount_type:"fixed_amount",
          merchant_price:0
        }
      };
    },
    validations: {
      formData: {
        name: {
          required,
        },
        remark: {
          required,
        }
      },
      formEditData: {
        name: {
          required,
        },
        remark: {
          required,
        }
      }
    },
    middleware: "authentication",
    async mounted() {
      this.reload();
    },
    created() {
      
    },
    methods: {
      formatDateTime(dateTime) {
        if (dateTime){
          return dateTime.replace(' ', ' at ');
        }
      },
      updateBankInfoFromChild:function(e){
        console.log(e)
        if (e.length > 0){
          if (this.formEditData.id !==""){
            e.forEach(element => {
              this.formEditData.products.push(
                {
                    id: element.id,
                    title: element.title,
                    images: element.images,
                    merchant_price: element.merchant_price,
                    discount_type: element.discount_type
                  },
              )
            });
          }else{
            e.forEach(element => {
              this.formData.products.push(
                {
                    id: element.id,
                    title: element.title,
                    images: element.images,
                    merchant_price: element.merchant_price,
                    discount_type: element.discount_type
                  },
              )
            });
          }
        }
      },
      resetQuickUpdate(){
        this.quickUpdateByCategory={
          discount_type:"fixed_amount",
          merchant_price:0
        }
        this.quickUpdateByItems={
          discount_type:"fixed_amount",
          merchant_price:0
        }
      },
      quickUpdateAll(type){
        if (type=="categories"){
          if (this.formEditData.id !==""){
            this.formEditData.categories.forEach(element => {
              element.merchant_price = this.quickUpdateByCategory.merchant_price
              element.discount_type = this.quickUpdateByCategory.discount_type
            })
          }else{
            this.formData.categories.forEach(element => {
              element.merchant_price = this.quickUpdateByCategory.merchant_price
              element.discount_type = this.quickUpdateByCategory.discount_type
            })
          }
        }else if (type=="products"){
          if (this.formEditData.id !=="" &&  this.formEditData.products.length > 0){
            this.formEditData.products.forEach(element => {
              element.merchant_price = this.quickUpdateByCategory.merchant_price
              element.discount_type = this.quickUpdateByCategory.discount_type
            })
          }else if (this.formData.products.length > 0){
            this.formData.products.forEach(element => {
              element.merchant_price = this.quickUpdateByCategory.merchant_price
              element.discount_type = this.quickUpdateByCategory.discount_type
            })
          }
        }
        this.resetQuickUpdate();
      },
    
      inputNumberOnly(event, parentModel, childModel) {
        const numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this[parentModel][childModel] = numericValue
      },
      inputNumberOnlyV2(event, parentModel, childModel) {
        var numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this[parentModel][childModel] = numericValue
        if(numericValue.length > 2){
          var val = numericValue.replace(/[^0-9]/g, '');
          console.log(val);
          val = val.substr(0, val.length-2)+"."+val.substr(-2);
          this[parentModel][childModel] = val;
        }
      },
      inputNumberOnlyV3(event, parentModel, childModel, index) {
        var numericValue = event.target.value.replace(/[^0-9.]/g, '');
        //this[parentModel][childModel] = numericValue
        this[parentModel].contract_selected[index][childModel] = numericValue
        if(numericValue.length > 2){
          var val = numericValue.replace(/[^0-9]/g, '');
          console.log(val);
          val = val.substr(0, val.length-2)+"."+val.substr(-2);
          this[parentModel].contract_selected[index][childModel] = val;
        }
      },
      inputNumberOnlyV4(event, parentModel, parent, childModel, index) {
        const numericValue = event.target.value.replace(/[^0-9.]/g, "");
        this[parentModel][parent][index][childModel] = numericValue;
      },
      inputNumberOnlyV5(event, parentModel, childModel, childModel2) {
        const numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this[parentModel][childModel][childModel2] = numericValue
      },
      convertCurrencyFormat(value, show00) {
        if (show00 == true) {
          return this.$refs.commonFunc.convertCurrencyFormat(value)
        } else {
          // Assuming this.transactionLimit is defined in your component's data
          return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00", "");
        }
      },
      showModal() {
        this.$refs['modal-create'].show()
        this.accessToken = this.data.assessToken;
        this.accessUsername = this.data.accessUsername;
        this.getCategory();
      },
      showEditModal() {
        this.$refs['modal-edit'].show()
        this.accessToken = this.data.assessToken;
        this.accessUsername = this.data.accessUsername;
        this.updateData = this.data.updateData;
        this.formEditData.id = this.data.updateData.account_db_code;
        this.getEditData(this.formEditData.id)
      },
      showDetails() {
        this.$refs['modal-view'].show()
        this.accessToken = this.data.assessToken;
        this.accessUsername = this.data.accessUsername;
        this.updateData = this.data.updateData;
      },
      showMerchant() {
        this.$refs['modal-merchants'].show()
        this.accessToken = this.data.assessToken;
        this.accessUsername = this.data.accessUsername;
        this.updateData = this.data.updateData;
        this.returnData = this.data.updateData.merchants;
      },
      getCategory(){
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        axios({
            method: "post",
            url: appConfig.WebsiteHost + 'controller/category/initCategoryList',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)

              resData.category_list.forEach(element => {
                if (this.formEditData.id==''){
                  if (element.id !=0){
                    this.formData.categories.push(
                        {
                        id: element.id,
                        category_name: element.category_name,
                        category_image: element.category_image,
                        merchant_price: 0,
                        discount_type:"fixed_amount"
                      }
                    )
                  }
                }else{
                  if (element.id !=0){
                    //find the new category and create a new category in existing category list
                    // in case the new category no auto add in existing category list
                    const isPresent = this.formEditData.categories.some(e => e.id === element.id);
                    if (!isPresent) {
                      this.formEditData.categories.push(
                        {
                        id: element.id,
                        category_name: element.category_name,
                        category_image: element.category_image,
                        merchant_price: 0,
                        discount_type:"fixed_amount"
                      })
                    }
                  }
                }
                
              });
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
      },
      getEditData(value){
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("accountDbCode", value);
        axios({
            // method: "post",
            // url: appConfig.WebsiteHost + 'controller/category/initCategoryList',
            method: "get",
            url: "/api/group_pricing_setting.json",
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              const returnData = resData.data.filter(e => e.account_db_code == value)[0] || {};
              console.log(returnData);
              this.formEditData= {
                id: returnData.account_db_code,
                name: returnData.name,
                remark: returnData.remark,
                categories: returnData.categories,
                products: returnData.products,
                poison: returnData.poison,
                created_date: returnData.created_date,
                updated_date: returnData.updated_date,
                created_by: returnData.created_by,
                last_update_by: returnData.last_update_by,
                status: returnData.status
              }
              setTimeout(()=>{
                this.getCategory();
              },500)
             
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
              this.loading = false;
              this.$Progress.finish();
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.loading = false;
                this.$Progress.finish();
            }
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
        });
      },
      showResetPasswordModal() {
        this.$refs['modal-reset-password'].show()
      },
      showModules() {
        this.$refs['modal-modules'].show()
        this.accessToken = this.data.assessToken;
        this.accessUsername = this.data.accessUsername;
        this.updateData = this.data.updateData;
      },
      addItems() {
        var items = []
        if (this.formEditData.id !==""){
          items = this.formEditData.products;
        }else{
          items =  this.formData.products;
        }
        this.updateData2 = {
          updateData: this.data.updateData,
          currenctItemSelected: items
        }
        setTimeout(() => this.$refs.modalFunc1.showModal(), 100);
      },
      filterByMerchant() {
        return this.returnData.filter((e) => {
            return (
            e.merchant_id.toLowerCase().includes(this.searchKey.toLowerCase()) ||
            e.merchant_name.toLowerCase().includes(this.searchKey.toLowerCase())
            );
        })
      },
      resetModal() {
        this.modalSubmit = false;
        this.modalLoading = false;
        this.returnData = [];
        this.returnData2 = [];
        this.searchKey="";
        this.formData={
          name:"",
          remark: "",
          categories: [],
          products: [],
          poison:{
            merchant_price: 0,
            discount_type:"percentage"
          }
        },
        this.formEditData= {
          id:"",
          name:"",
          remark: "",
          categories: [],
          products: [],
          poison:{
            merchant_price: 0,
            discount_type:"percentage",
          },
          created_date:"",
          updated_date:"",
          created_by: "",
          last_update_by: "",
          status:""
        }
      },
      addRequest(){
        this.modalSubmit = true
        this.$v.formData.$touch();
        console.log((this.$v.formData.$invalid))
        if (this.$v.formData.$invalid) {
          return;
        }
        else {
          this.$Progress.start();
          this.modalLoading = true;
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername", this.accessUsername);
          bodyFormData.append("name", this.formData.name);
          bodyFormData.append("remark", this.formData.remark);
          bodyFormData.append("categories", JSON.stringify(this.formData.categories));
          bodyFormData.append("products", JSON.stringify(this.formData.products));
          bodyFormData.append("poison", JSON.stringify(this.formData.poison));
          axios({
              method: "post",
              url: appConfig.DemoAPI + '',
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                Swal.fire({
                  icon: 'success',
                  title: 'Group Created',
                  html: 'New group has been successfully created!',
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                }).then(() => {
                  this.$refs['modal-create'].hide()
                  this.$emit('callParentFunction');
                })
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
            })
            .catch((error)=> {
                this.modalLoading = false;
                this.modalSubmit = false
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
          });
        }
      },
      updateRequest(){
        this.modalSubmit = true
        this.$v.formEditData.$touch();
        console.log((this.$v.formEditData.$invalid))
        if (this.$v.formEditData.$invalid) {
          return;
        }
        else {
          this.$Progress.start();
          this.modalLoading = true;
          var bodyFormData = new FormData();
          
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername", this.accessUsername);
          bodyFormData.append("accountDbCode", this.formEditData.id);
          bodyFormData.append("name", this.formEditData.name);
          bodyFormData.append("remark", this.formEditData.remark);
          bodyFormData.append("categories", JSON.stringify(this.formEditData.categories));
          bodyFormData.append("products", JSON.stringify(this.formEditData.products));
          bodyFormData.append("poison", JSON.stringify(this.formEditData.poison));
          bodyFormData.append("status", this.formEditData.status);
          axios({
              method: "post",
              url: appConfig.DemoAPI + '',
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                Swal.fire({
                  icon: 'success',
                  title: 'Group Updated',
                  html: 'Group has been successfully updated!',
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                }).then(() => {
                  this.$refs['modal-edit'].hide()
                  this.$emit('callParentFunction');
                })
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
            })
            .catch((error)=> {
                this.modalLoading = false;
                this.modalSubmit = false
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
          });
        }
      },
      updateUserRequest(){
        this.modalSubmit = true
        this.$v.formEditData.$touch();
        console.log((this.$v.formEditData.$invalid))
        var NoModuleEnabled = this.formEditData.permissions.map((item) => parseInt(item.view_permission)).includes(1);
        if (this.$v.formEditData.$invalid) {
          return;
        }
        else if (!NoModuleEnabled) {
          Swal.fire(
            {
            icon: 'error',
            title: 'Oops...',
            html: `Please enable at least one permissions.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
        }else {
          this.$Progress.start();
          this.modalLoading = true;
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername", this.accessUsername);
          bodyFormData.append("accountDbCode", this.formEditData.id);
          bodyFormData.append("name", this.formEditData.name);
          bodyFormData.append("fullName", this.formEditData.full_name);
          bodyFormData.append("nric", this.formEditData.nric_no);
          bodyFormData.append("email", this.formEditData.email);
          bodyFormData.append("position", this.formEditData.position);
          bodyFormData.append("status", this.formEditData.status);
          bodyFormData.append("loginBlock", this.formEditData.login_block);
          bodyFormData.append("signingAuthority", this.formEditData.signing_authority);
          bodyFormData.append("permissions", JSON.stringify(this.formEditData.permissions));
          axios({
              method: "post",
              url:  appConfig.APIHost + 'controller/user/editUser',
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                Swal.fire({
                  icon: 'success',
                  title: 'User Account Updated',
                  html: 'Your user account has been successfully updated!',
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                }).then(() => {
                  this.$refs['modal-edit-user'].hide()
                  this.$emit('callParentFunction');
                })
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
            })
            .catch((error)=> {
                this.modalLoading = false;
                this.modalSubmit = false
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
          });
        }
      },
      async reload() {
        try {
          await this.$nextTick(); // Wait for the component to be fully mounted
          const commonFunc = this.$refs.commonFunc;
          if (commonFunc && commonFunc.getFullData) {
            const data = await commonFunc.getFullData();
            this.account_type = data.account_type;
            
          } else {
            // Handle the case when commonFunc or getFullData is undefined
            // Perform appropriate actions or show a fallback message to the user
          }
        } catch (error) {
          // Handle the error silently without propagating it further
        }
      },
    }
  };
</script>
<style scoped>

@media (min-width: 992px){
  .form-select-custom{
  max-width: 150px
}
.price-input{
  max-width: 100px;
}
}
</style>