<template>
  <div>
    <b-modal @hidden="resetModal" ref="modal-select-mutilple-products" centered hide-footer size="lg" :no-close-on-backdrop="true" content-class="overflow-hidden">
      <template #modal-title>
        <h5 class="text-info mb-0 text-dark">
          <i class="bi bi-plus-circle text-primary me-1"></i> Special Product Pricing for Group
        </h5>
      </template>
      <div class="row align-items-top">
        <!-- datatable -->
        <div class="col-12 mb-2">
          <div>
            <label class="align-items-center w-100">
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="Keyword..."
                class="form-control"
              ></b-form-input>
            </label>
          </div>
        </div>
        <div class="col-12 mb-3">
            <div class="table-responsive mb-0">
              <!-- striped -->
                <b-table
                  class="text-nowrap align-middle mb-0 custom-datatable-table rounded-3"
                  :items="itemsList"
                  :fields="visibleFields()"
                  responsive="sm"
                  striped
                  :per-page="perPage2"
                  :current-page="currentPage2"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  :busy="loading"
                  show-empty
                >        
                <template>
                  <div class="text-center py-5 text-center text-muted" slot="table-busy">
                    <div
                      class="spinner-border text-secondary my-2"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <br />
                    <div>Loading...</div>
                  </div>
                </template>
                <template slot="empty">
                  <div class="text-center text-muted py-5">
                     No Data Available
                  </div>
                </template>
                <template slot="emptyfiltered">
                  <div class="text-center text-muted py-5">
                     There are no records matching your keyword
                  </div>
                </template>
                <template #cell(isChecked)="data">
                  <div class="form-check ms-2 d-flex align-items-center">
                    <input class="form-check-input me-2 font-size-18" type="checkbox" true-value="1" false-value="0" v-model="data.item.isChecked" :id="data.item.index">
                    <label class="form-check-label font-size-18" :for="data.item.index">
                      <img v-if="data.item.images.length" :src="data.item.images[0]" class="rounded ms-2 bg-white" alt="..." style="height: 60px;">
                    </label>
                  </div>
                </template>

                <template #cell(title)="data">
                  <label :for="data.item.index" class="position-relative d-block">
                    <div class="mb-0 d-block fw-medium" >{{data.item.title}}</div>
                    <span v-if="data.item.status=='out_of_stock'" class="badge bg-danger fw-normal font-size-12 me-1 text-capitalize text-uppercase lh-0"> Out of Stock</span>
                    <span v-else-if="data.item.status=='available'" class="badge bg-success fw-normal font-size-12 me-1 text-capitalize text-uppercase lh-0">Available</span>
                  </label>
                </template>

              </b-table>
            </div>
            
        </div>
        <div class="col-12 mb-3" v-if="itemsList.length && !loading">
          <div class="col">
            <div
              class="dataTables_paginate paging_simple_numbers float-end"
            >
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage2"
                  :total-rows="totalData"
                  :per-page="perPage2"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
        <!-- datatable end -->
        <div class="col-12">
          <hr>
          <div class="d-grid gap-2 d-md-block text-end">
            <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-select-mutilple-products'].hide()">Cancel</button>
            <button type="button" class="btn fw-medium btn-primary" @click="update" :disabled="modalLoading || disabled">
              <span v-if="modalLoading"> Updating...</span>
              <span v-else> Select </span>
              <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <Common ref="commonFunc" />
  </div>
</template>
<script>
import appConfig from "@/app.config";
import axios from "axios";
import Swal from "sweetalert2";
import Common from "@/components/common";
/**
 * Starter page
 */
export default {
  components: {
    Common,
  },
  props: {
    data: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      updateData: {},
      accessToken: '',
      accessEmail: '',
      accessPhone: '',
      accessUsername: '',
      loading: false,
      disabled: false,
      modalSubmit: false,
      modalLoading: false,
      returnData: [],
      selectedItems:[],
      itemsList:[],
      //datatable
      totalData:0,
      totalRows: 1,
      currentPage2: 1,
      perPage2: 20,
      filter: "",
      filterOn: [],
      sortBy: "title",
      sortDesc: false,
      fields: [
        {
          key: "isChecked",
          label:"Product",
          thClass:"user-select-none ",
          tdClass: "align-middle w-custom",
        },
        // {
        //   key: "images",
        //   label:"Product Image",
        //   thClass:"user-select-none",
        //   tdClass: "align-middle",
        //   sortable: false,
        // },
         {
          key: "title",
          label:"",
          thClass:"user-select-none",
          tdClass: "align-middle",
          sortable: true,
        },
        
        // {
        //   key: "status",
        //   label:"Status",
        //   thClass:"user-select-none",
        //   tdClass: "align-middle",
        //   sortable: true,
        // }
        
      ]
    };
  },
  middleware: "authentication",
  async mounted() {
    //   this.title = PageTitle
    //   this.items[1].text = PageTitle
    //   // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    //   // this.$refs.main.changeVerticalTopBar("bill",true)
    //   // this.$refs.main.setShowFooterCert(false)
    //   // this.$refs.main.setPageTitle('title')
    //   console.log( this.$refs.commonFunc.getPhone())
    //   this.accessToken = this.$refs.commonFunc.getToken()
    //   this.accessUsername = this.$refs.commonFunc.getUsername()
    //   await this.getData();
    await this.reload();
  },
  created() {},
  methods: {
    visibleFields() {
      return this.fields.filter(field => {
      // Include all fields except the "Action" column if the condition is true
      return field.key !== 'action' || this.create_modify_permission;
    });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalData = filteredItems.length;
      this.currentPage2 = 1;
    },
    inputNumberOnly(event, parentModel) {
      const numericValue = event.target.value.replace(/[^0-9.]/g, '');
      this.formSuccessData[parentModel] = numericValue;
    },
    inputNumberOnlyV2(event, parentModel, childModel, index) {
        var numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this[parentModel][index][childModel] = numericValue
    },
    convertCurrencyFormat(value, show00) {
      if (show00 == true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value)
      } else {
        // Assuming this.transactionLimit is defined in your component's data
        return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00", "");
      }
    },
    async getProductsList(){
        this.itemsList = []
        console.log("getProductsList")
        this.$Progress.start();
        this.loading = true
        this.disabled = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("page", "1");
        bodyFormData.append("limit", "99999");
        axios({
          method: "get",
          url: `${appConfig.WebsiteHost}/controller/product/getAllProductListByPagination?category=&sort=&limit=99999&page=1&keyword=&tag=`,
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              this.selectedItems = []
              this.itemsList = []
              resData.products.forEach((element, index) => {
                console.log(index)
                const isPresent = this.updateData.currenctItemSelected.some(e => e.id === element.id);
                  if (!isPresent) {
                      this.itemsList.push({
                          index: index,
                          id: element.id,
                          title: element.title,
                          price: element.price,
                          images: element.images,
                          merchant_price: 0,
                          discount_type: "fixed_amount",
                          status: element.status,
                          isChecked: 0,
                          keyword: `${element.title}`
                      });
                  }
              })

              this.totalData = this.itemsList.length;
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.disabled = false
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.disabled = false
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
        });
    },
    showModal() {
      this.$refs['modal-select-mutilple-products'].show()
      this.accessToken = this.data.accessToken;
      this.accessUsername = this.data.accessUsername;
      this.updateData = this.data.updateData
      this.getProductsList();
    },
    resetModal() {
      this.modalSubmit = false;
      this.modalLoading = false;
      this.itemsList= []
      this.selectedItems = []
      this.currentPage2 = 1
      this.filter = ""
    },
    update(){
      this.$refs['modal-select-mutilple-products'].hide();
      var updateBackToParent = []
      this.itemsList.forEach(element => {
        if (element.isChecked==1){
          updateBackToParent.push(element)
        }
      });
      this.$emit('updateItemsList',updateBackToParent);
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted
        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          const permission = data.modules.filter(e => e.name==='payout')
          if (permission) {
            this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
          } else {
            console.log('Module with name "payout" not found.');
          }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
  }
};
</script>
<style>
.w-custom{
  width: 40px !important;
}
</style>